export const config = {
    FILE_PATH: "",
    API_ENDPOINT: "https://kratos-demo.icapkenya.org/api/v1/",
    SUMMARIZED_IFRAME_SOURCES: [],
    SUPERSET: {
      DOMAIN: 'https://shieldtest-analytics.icapkenya.org',
      API_ENDPOINT: "https://shieldtest-analytics.icapkenya.org/api/v1/security",
      API_PROVIDER: "db",
      USER_ADMINISTRATOR: {
      	USERNAME: "akm2251",
        PASSWORD: "Awesome2019#"
      },
      USER_GUEST: {
          USERNAME: "guest",
      },
      DASHBOARDS: [
        "742932fc-4cdb-46c3-8e6f-ba64a818c6eb", // IBS
        "3614841c-6680-4e74-af33-ce5f2ea0b357", // IFBS
        "0cae2e49-8d73-4f65-bd2c-db34df89fde8", // SARI/ILI
        "2f2a9996-736d-43c2-9921-a7700f070503", // COVID-19
        "032833ea-51ec-496d-9577-255895f01671", // EBS
	"f2a130f2-fbae-45b0-b59b-771c74dad98b", // ADAM
	"076b662c-b495-4f67-9156-372415e72533" // EBridge 
      ],
    }
};
